// "use client";

import {Dropdown, Navbar} from "flowbite-react";
import {useLocation} from "react-router-dom"; // Import useLocation
import {useEffect, useState, useContext} from "react";
import {useAuth} from '../components/AuthContext';
import ThemeContext from "./ThemeContext";
import NotificationsBtn from "./Buttons/NotificationsBtn";

export function Nav() {
    const {toggleTheme} = useContext(ThemeContext);
    const location = useLocation(); // Použijeme na získanie aktuálnej cesty
    const {token, logout} = useAuth();
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [devices, setDevices] = useState([]);
    const isAnyDeviceActive = devices.some((device) => location.pathname === `/device/${device.id}`);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Pridaj správny token
                    },
                });
                
                if (!response.ok) {
                    throw new Error('Failed to fetch house details');
                }

                const data = await response.json();
                setUser(data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch sensor data');
                setLoading(false);
            }
        };

        const fetchDevices = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/user`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Ak vyžaduje autentifikáciu
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) throw new Error('Failed to fetch devices');

                const data = await response.json();
                setDevices(data); // Nastavenie zariadení do state
            } catch (error) {
                console.error('Error fetching devices:', error);
            }
        };

        fetchDevices();
        fetchUser();
    }, [token]);

    
    const handleLogout = () => {
        logout();
        // navigate('/login');
    };

    return (
        <Navbar className="dark:bg-myGrey bg-myGrey p-3 text-white" fluid rounded>
            <Navbar.Brand href="/">
                <span className="self-center whitespace-nowrap text-3xl font-bold dark:text-white">HomeNest</span>
            </Navbar.Brand>
            
            

            <div className="flex sm:order-2">
                <NotificationsBtn></NotificationsBtn>

                <button
                    onClick={toggleTheme}
                    className="flex p-2 mx-3 rounded-full bg-gray-200 dark:bg-gray-700 transition-colors duration-300 sm:static hover:scale-110"
                >
                    <svg className="w-6 h-6 text-gray-800 dark:text-gray-200" fill="none" stroke="currentColor"
                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"></path>
                    </svg>
                </button>
                <Dropdown 
                    arrowIcon={false}
                    inline
                    label={
                        <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 transition-colors duration-300 hover:scale-110">
                            <svg className="absolute w-12 h-12 text-gray-400 -left-1" fill="currentColor"
                                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                      clipRule="evenodd"></path>
                            </svg>
                        </div>
                    }
                >
                    {user ? (
                        <Dropdown.Header>
                            <span className="block text-sm">{user.name}</span>
                            <span className="block truncate text-sm font-medium">{user.email}</span>
                        </Dropdown.Header>
                    ):(<></>)}
                    
                    <Dropdown.Item href="/">Dashboard</Dropdown.Item>
                     {/*<Dropdown.Item href="/settings">Settings</Dropdown.Item> */}
                    <Dropdown.Divider/>
                    <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
                </Dropdown>
                <Navbar.Toggle/>
            </div>

            <Navbar.Collapse>
                <Navbar.Link className="navItem" href="/" active={location.pathname === "/"}>Dashboard</Navbar.Link>
                <Navbar.Link className="navItem" href="/Houses" active={location.pathname === "/Houses"}>Houses</Navbar.Link>
                {/* Dropdown pre Devices */}
                <li className="navItem">
                    <Dropdown
                        label="Devices"
                        inline={true}
                        className={`font-bold text-xl ${
                            isAnyDeviceActive ? 'text-blue-500' : 'text-white'
                        }`}
                    >
                        {devices.length > 0 ? (
                            devices.map((device) => (
                                <Dropdown.Item
                                    key={device.id}
                                    href={`/device/${device.id}`}
                                    className={`p-4 text-white font-bold text-xl ${
                                        location.pathname === `/device/${device.id}` ? 'bg-blue-500' : 'hover:bg-gray-700'
                                    }`}
                                >
                                    {device.name}
                                </Dropdown.Item>
                            ))
                        ) : (
                            <Dropdown.Item className="p-4 text-white font-bold text-xl">
                                No devices found
                            </Dropdown.Item>
                        )}
                    </Dropdown>
                </li>
            </Navbar.Collapse>
        </Navbar>
    );
}
