import React, { useState, useEffect } from 'react';
import {formatDistanceToNow} from "date-fns";
import {sk} from "date-fns/locale";

const NotificatoinButton = ({ notification, markAsRead }) => {

    const handleMarkNotification = (newId) => {
        markAsRead(newId);
    };

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const minutesAgo = formatDistanceToNow(date, { locale: sk, addSuffix: true });
        return minutesAgo;
    };

    return (
        <button
            onClick={() => handleMarkNotification(notification.id)}
            disabled={notification.is_seen}
            className="w-full flex px-2 py-2 hover:bg-gray-300 dark:hover:bg-gray-600"
        >
            <div className="w-full px-1">
                <div className="text-left pb-1 text-gray-500 text-sm mb-1.5 dark:text-gray-400">
                    {notification.is_seen ? (
                        notification.message
                    ) : (
                        <span className="font-semibold text-sm text-gray-900 dark:text-white">
                                            {notification.message}
                                        </span>
                    )}
                </div>
                <div className="text-right text-sm text-blue-600 dark:text-blue-500">
                    {formatDate(notification.created_at)}
                </div>
            </div>
        </button>
    );
};

export default NotificatoinButton;
