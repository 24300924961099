import React, {useEffect, useState} from 'react';
import {useAuth} from '../components/AuthContext';
import NotVerified from './errors/NotVerified';
import {Link} from "react-router-dom";
import AddHouseModal from '../components/modal/AddHouseModal';
import {Nav} from "./Nav";
import HouseInvitationModal from './modal/HouseInvitationModal';
import io from "socket.io-client";
import {Dropdown} from "flowbite-react";
import {formatDistanceToNow} from "date-fns";
import {sk} from "date-fns/locale";
import NotificatoinButton from "./Buttons/NotificationButton";

const Notifications = () => {
    const { token, userId } = useAuth();
    const [error, setError] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch notifications');
                }

                const data = await response.json();
                setNotifications(data);
            } catch (error) {
                setError('Failed to fetch notifications');
            }
        };
        fetchNotifications();
    }, [token, userId]);

    const markAsRead = async (notificationId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/notification/${notificationId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to mark notification as read');
            }

            // Update notifications locally
            setNotifications((prevNotifications) =>
                prevNotifications.map((notif) =>
                    notif.id === notificationId ? { ...notif, is_seen: true } : notif
                )
            );

            // Update notification count
            setNotificationCount((prevCount) => Math.max(prevCount - 1, 0));
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentNotifications = notifications.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(notifications.length / itemsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <>
            <Nav></Nav>
            <div className="mainContext">
                <section className='headerSection'>
                    <h2 className="headTitle">Notifications</h2>
                </section>

                {error ? (
                    error === 'Verification error.' ? (
                        <div className="text-red-500"><NotVerified/></div>
                    ) : (
                        <div className="text-red-500">{error}</div>
                    )
                ) : (
                    <section className="w-6/12 mx-auto">
                        {currentNotifications.length > 0 ? (
                            <>
                                {currentNotifications.map((notification) => (
                                    <NotificatoinButton
                                        key={notification.id}
                                        notification={notification}
                                        markAsRead={markAsRead}
                                    />
                                ))}
                                <div className="flex justify-between mt-4">
                                    <button className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                    >
                                        <svg className="w-3.5 h-3.5 me-2 rtl:rotate-180" aria-hidden="true"
                                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="2" d="M13 5H1m0 0 4 4M1 5l4-4"/>
                                        </svg>
                                        Prev
                                    </button>
                                    <span className="text-sm text-gray-600">Page {currentPage} of {totalPages}</span>

                                    <button className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                        <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true"
                                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                        </svg>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <p className="px-4 py-2 text-sm text-gray-500 dark:text-gray-400">You have no notifications</p>
                        )}
                    </section>
                )}
            </div>
        </>
    );
};

export default Notifications;
