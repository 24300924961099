import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from "./AuthContext";
import AddDeviceModal from "./modal/AddDeviceModal";
import NotVerified from "./errors/NotVerified";
import DeviceCard from "./cardsComponents/DeviceCard";
import RoomCharts from '../components/RoomCharts';
import {Nav} from "./Nav";
import LoadingSpinner from "./LoadingSpinner"; // Predpokladám, že máš komponent na zobrazenie grafov

const Room = () => {
    const { houseId, roomId } = useParams(); // Získanie parametrov z URL
    const { token } = useAuth();
    const [room, setRoom] = useState(null);
    const [role, setRole] = useState(null);
    const [devices, setDevices] = useState(null);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [activeTab, setActiveTab] = useState('devices'); // Stav pre aktívny tab

    // Načítanie detailov izby
    useEffect(() => {
        const fetchRoom = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/room/${roomId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch room data');
                }

                const data = await response.json();
                setRoom(data.room);
                setDevices(data.room.devices);
                setRole(data.role);
            } catch (err) {
                setError(err.message);
            }
        };

        if (houseId && roomId) {
            fetchRoom();
        }
    }, [houseId, roomId, token]);


    return (
        <>
            {/*<Header/>*/}
            <Nav></Nav>
            <div className="mainContext">
                {error ? (
                    error === 'Verification error.' ? (
                        <div className="text-red-500"><NotVerified/></div>
                    ) : (
                        <div className="text-red-500">{error}</div>
                    )
                ) : room ? (
                    <div>
                        <section className="headerSection flex flex-col sm:flex-row sm:items-center sm:justify-between">
                            <h2 className="headTitle">{room.name}</h2>

                            {/* Taby pre prepínanie medzi zobrazeniami */}
                            <ul className="flex flex-wrap text-lg font-medium text-center text-gray-500 dark:border-gray-700 dark:text-gray-400 mt-4 sm:mt-0">
                                <li className="me-2">
                                    <button
                                        onClick={() => setActiveTab('devices')}
                                        className={`inline-block pt-4 px-4 rounded-t-lg ${
                                            activeTab === 'devices'
                                                ? 'text-blue-600 bg-gray-100 dark:bg-gray-800 dark:text-blue-500'
                                                : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'
                                        }`}
                                    >
                                        Devices
                                    </button>
                                </li>
                                <li className="me-2">
                                    <button
                                        onClick={() => setActiveTab('charts')}
                                        className={`inline-block pt-4 px-4 rounded-t-lg ${
                                            activeTab === 'charts'
                                                ? 'text-blue-600 bg-gray-100 dark:bg-gray-800 dark:text-blue-500'
                                                : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'
                                        }`}
                                    >
                                        Charts
                                    </button>
                                </li>
                            </ul>

                            {activeTab === 'devices' && role === 'owner' ? (
                                <div className="flex space-x-2 mt-4 sm:mt-0">
                                    <button
                                        onClick={() => setShowModal(true)}
                                        className="plusBtn"
                                        type="button"
                                    >
                                        <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                            fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                strokeWidth="2" d="M1 5h12 M7 0v10"/>
                                        </svg>
                                        <span className="sr-only">Pridať miestnosť</span>
                                    </button>
                                    <button
                                        onClick={() => setShowDelete(!showDelete)}
                                        className="minusBtn"
                                        type="button"
                                    >
                                        {showDelete ? null : (
                                            <svg className="w-4 h-4" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none" viewBox="0 0 14 10">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M1 5h12"/>
                                            </svg>
                                        )}
                                        <span>{showDelete ? 'Zrušiť' : ''}</span>
                                    </button>
                                </div>
                            ) : null}
                        </section>



                        {/* Obsah podľa aktívneho tabu */}
                        <section>
                            {activeTab === 'devices' ? (
                                <>
                                    <AddDeviceModal
                                        showModal={showModal}
                                        setShowModal={setShowModal}
                                        token={token}
                                        roomId={roomId}
                                    />

                                    <ul className="cardGrid">
                                        {devices && devices.length > 0 ? (
                                            devices.map((device) => (
                                                <DeviceCard key={device.id} 
                                                    deviceName={device.name}
                                                    deviceId={device.id}
                                                    role={role}
                                                />
                                            ))
                                        ) : (
                                            <li>No devices.</li>
                                        )}
                                    </ul>
                                </>
                            ) : (
                                <RoomCharts room={room} devices={devices}/> // Komponent na zobrazenie grafov
                            )}
                        </section>
                    </div>
                ) : (
                    <LoadingSpinner></LoadingSpinner>
                )}
            </div>
        </>
    );
};

export default Room;
