import React, {useEffect, useState, useContext} from "react";
import {Link} from "react-router-dom";
import {Dropdown} from "flowbite-react";
import { format, formatDistanceToNow } from 'date-fns';
import { sk } from 'date-fns/locale'; // Locale pre slovenčinu
import {useAuth} from "../AuthContext";
import io from "socket.io-client";
import LoadingSpinner from "../LoadingSpinner";


const NotificationsBtn = () => {
    const [loading, setLoading] = useState(true);
    const { token, userId } = useAuth();
    const [notificationCount, setNotificationCount] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/notificationsPanel`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch notifications');
                }

                const data = await response.json();
                setNotifications(data);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch notifications');
                setLoading(false);
            }
        };

        const fetchNotificationsCount = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/notificationsCount`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch notifications count');
                }

                const data = await response.json();
                setNotificationCount(data);
            } catch (error) {
                setError('Failed to fetch notifications count');
            }
        };

        fetchNotificationsCount();
        fetchNotifications();

        const socket = io('https://socketio-service.onrender.com', { transports: ['websocket'] });

        socket.on(userId, (newData) => {
            setNotificationCount(newData);
        });
        socket.on(userId+"alert", (newNotification) => {
            setNotifications((prevNotifications) => {
                const maxNotifications = 5; // Nastav maximálny počet notifikácií
                const updatedNotifications = [newNotification[0], ...prevNotifications];
                return updatedNotifications.slice(0, maxNotifications); // Udržuje maximálny počet notifikácií
              });
        });

        socket.on('connect_error', (err) => {
            console.error('Socket connection error:', err);
            setError('Failed to connect to notification server');
        });

        return () => {
            socket.disconnect();
        };
    }, [token, userId]);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const minutesAgo = formatDistanceToNow(date, { locale: sk, addSuffix: true });
        return minutesAgo;
    };

    const markAsRead = async (notificationId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/notification/${notificationId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to mark notification as read');
            }

            // Aktualizuj stav notifikácií lokálne
            setNotifications((prevNotifications) =>
                prevNotifications.map((notif) =>
                    notif.id === notificationId ? { ...notif, is_seen: true } : notif
                )
            );

            // Aktualizuj počet notifikácií
            setNotificationCount((prevCount) => Math.max(prevCount - 1, 0));
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    return (
        <Dropdown
            arrowIcon={false}
            inline
            label={
                <div className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-gray-200 dark:bg-gray-700 rounded-full transition-colors duration-300 hover:scale-110">
                    <img
                        src={process.env.PUBLIC_URL + '/images/alert.svg'}
                        alt="settings"
                        className="max-w-7 dark:invert"
                    />
                    {notificationCount > 0 && (
                        <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
                            {notificationCount}
                        </div>
                    )}
                </div>
            }
        >
            <div className="rounded-xl shadow-xl w-80 pt-2">
                <Dropdown.Header className="text-center text-lg font-bold text-gray-800 dark:text-gray-200">
                    Notifications
                </Dropdown.Header>
                {notifications.length > 0 ? (
                    notifications.map((notification) => (
                        <div key={notification.id}>
                            <button
                                onClick={() => markAsRead(notification.id)}
                                disabled={notification.is_seen}
                                className="w-full flex px-2 py-2 hover:bg-gray-300 dark:hover:bg-gray-600"
                            >
                                <div className="w-full px-1">
                                    <div className="text-left pb-1 text-gray-500 text-sm mb-1.5 dark:text-gray-400">
                                        {notification.is_seen ? (
                                            notification.message
                                        ) : (
                                            <span className="font-semibold text-sm text-gray-900 dark:text-white">
                                                {notification.message}
                                            </span>
                                        )}
                                    </div>
                                    <div className="text-right text-sm text-blue-600 dark:text-blue-500">
                                        {formatDate(notification.created_at)}
                                    </div>
                                </div>
                            </button>
                            <Dropdown.Divider />
                        </div>
                    ))
                ) : ( loading ? (
                    <LoadingSpinner></LoadingSpinner>
                    ) : (
                        <p className="px-4 py-2 text-sm text-gray-500 dark:text-gray-400">You have no notifications</p>
                    )
                )}
                <Link to='/notifications'
                      className="block py-2 text-sm font-medium text-center text-gray-900 rounded-b-lg bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-600 dark:text-white">
                    <div className="inline-flex items-center ">
                        <svg className="w-4 h-4 me-2 text-gray-500 dark:text-gray-400" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                            <path
                                d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
                        </svg>
                        View all
                    </div>
                </Link>
            </div>

        </Dropdown>
    );
};

export default NotificationsBtn;
