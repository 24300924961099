import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from './AuthContext';
import AddRoomModal from './modal/AddRoomModal';
import NotVerified from './errors/NotVerified';
import RoomCard from './cardsComponents/RoomCard';
import {Nav} from "./Nav";
import LoadingSpinner from "./LoadingSpinner";

const HouseDetail = () => {
    const { id } = useParams(); // Získanie ID domu z URL
    const { token } = useAuth();
    const [house, setHouse] = useState(null);
    const [role, setRole] = useState(null);
    const [rooms, setRooms] = useState([]); // Izby z API
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {
        const fetchHouse = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/houses/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    if(response.status === 403){
                        throw new Error('You haven\'t permission.');    
                    }
                    throw new Error('Failed to fetch house details');
                }

                const data = await response.json();
                setHouse(data.house);
                setRole(data.role);
                setRooms(data.house.rooms || []);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchHouse();
    }, [id, token]);

    // Funkcia na odstránenie izby
    const handleDeleteRoom = async (roomId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/house/${id}/rooms/${roomId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete room');
            }

            // Po úspešnom odstránení izby ju odstránime aj zo state
            setRooms((prevRooms) => prevRooms.filter(room => room.id !== roomId));
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <>
            {/*<Header />*/}
            <Nav></Nav>
            <div className="mainContext">
            {error ? (
                error === 'Verification error.' ? (
                    <div className="text-red-500"><NotVerified/></div>
                ) : (
                    <div className="text-red-500">{error}</div>
                )
            ) : (house ? (
                <>
                    <div className="headerSection">
                        <h1 className="headTitle">{house.name}</h1>
                        {role === 'owner' ? 
                            <div className="flex space-x-4">
                                {showDelete ? null : (<button 
                                    onClick={() => setShowModal(true)} 
                                    className="plusBtn"
                                    type="button"
                                >
                                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12 M7 0v10"/>
                                    </svg>
                                    <span className="sr-only">Pridať miestnosť</span>
                                </button>)}
                                
                                <button 
                                    onClick={() => setShowDelete(!showDelete)}
                                    className="minusBtn"
                                    type="button"
                                >
                                        {showDelete ? null : (
                                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12"/>
                                            </svg>
                                        )}
                                    <span>{showDelete ? 'Zrušiť' : ''}</span>
                                </button>
                            </div> : <></>
                        }
                    </div>
        
                    <AddRoomModal 
                        showModal={showModal} 
                        setShowModal={setShowModal} 
                        setRooms={setRooms} // Odovzdaj setRooms do modálu
                        token={token} 
                        houseId={id} 
                    />
        
                    <section>
                        <ul className="cardGrid">
                            {rooms.length > 0 ? (
                                rooms.map((room) => (
                                    <RoomCard 
                                        key={room.id} 
                                        houseId={house.id} 
                                        roomId={room.id} 
                                        roomName={room.name} 
                                        showDelete={showDelete} // Posielame flag pre zobrazenie tlačidla odstránenia
                                        onDelete={() => handleDeleteRoom(room.id)} // Callback na odstránenie izby
                                    />
                                ))
                            ) : (
                                <li>Žiadne izby.</li>
                            )}
                        </ul>
                    </section>
                </>
            ) : (
                <LoadingSpinner></LoadingSpinner>
            ))
    }
</div>

        </>
    );
};

export default HouseDetail;
