import React, {useEffect, useState} from 'react';
import {useAuth} from '../components/AuthContext';
import NotVerified from './errors/NotVerified';
import DeviceChartHour from "./DeviceChartHour";
import AddDeviceChartModal from "./modal/AddDeviceChartModal";
import {Nav} from "./Nav";
import LoadingSpinner from "./LoadingSpinner";

const Dashboard = () => {
    const {token, userId} = useAuth();
    const [protectedData, setProtectedData] = useState(null);
    const [devices, setDevices] = useState([]);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {
        const fetchHouses = async (userId) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/dashboard/devices`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                });
        
                if (!response.ok) {
                    console.log("ERROR");
                    throw new Error('Failed to fetch houses');
                }
        
                const data = await response.json();
                setDevices(data);
            } catch (err) {
                setError(err.message);
            }
        };

        const fetchProtectedData = async () => {
            if (!token) {
                setError('Žiadny token. Prosím prihláste sa.');
                return;
            }

            try {
                const api_url = process.env.REACT_APP_API_URL;
                const response = await fetch(`${api_url}/user`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    if (response.status === 403) {
                        throw new Error('Verification error.');
                    }
                    throw new Error('Chyba pri načítaní chráneného obsahu.');
                }

                const data = await response.json();
                setProtectedData(data);

                fetchHouses(data.id);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchProtectedData();
    }, [token]);

        // Funkcia na odstránenie Device
        const handleDeleteRoom = async (deviceId) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/dashboard/devices/${deviceId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
    
                if (!response.ok) {
                    throw new Error('Failed to delete room');
                }
    
                // Po úspešnom odstránení izby ju odstránime aj zo state
                setDevices((prevRooms) => prevRooms.filter(room => room.id !== deviceId));
            } catch (err) {
                setError(err.message);
            }
        };

    return (
        <>
        {/*<Header></Header>*/}
        <Nav></Nav>
        <div className="mainContext">
            <section className='headerSection'>
                <h2 className="headTitle">Dashboard</h2>
                <div className="flex space-x-4">
                    {showDelete ? null : (
                        <button
                            onClick={() => setShowModal(true)}
                            className="plusBtn"
                            type="button"
                            >
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12 M7 0v10"/>
                            </svg>
                            <span className="sr-only">Pridať dom</span>
                        </button>
                    )}   
                    
                    <button 
                        onClick={() => setShowDelete(!showDelete)}
                        className="minusBtn"
                        type="button"
                        >
                            {showDelete ? null : (
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12"/>
                                </svg>
                            )}
                        <span>{showDelete ? 'Zrušiť' : ''}</span>
                    </button>
                </div>
            </section>
            
            {error ? (
                error === 'Verification error.' ? (
                    <div className="text-red-500"><NotVerified/></div>
                ) : (
                    <div className="text-red-500">{error}</div>
                )
            ) : (
                protectedData ? (
                    <section>
                        <section>
                            <AddDeviceChartModal
                                showModal={showModal}
                                setShowModal={setShowModal}
                                setDevices={setDevices} // Odovzdaj setRooms do modálu
                                token={token}
                                userId={userId}
                            />
                            </section>

                        <div className='m-3'>
                                <ul>
                                    {devices.length > 0 ? (
                                        devices.map((device) => (
                                            <li key={device.id} className="mb-4">
                                                <DeviceChartHour
                                                    key={device.id} 
                                                    device={device}
                                                    showDelete={showDelete}
                                                    onDelete={() => handleDeleteRoom(device.id)}
                                                 />
                                            </li>
                                        ))
                                    ) : (
                                        <p>Nemáte žiadne Zariadenia.</p>
                                    )}
                                </ul>
                        </div>
                    </section>
                ) : (
                    <LoadingSpinner></LoadingSpinner>
                )
            )}
        </div>
        </>
    );
};

export default Dashboard;
