import React, {useEffect, useState} from 'react';
import {useAuth} from '../components/AuthContext';
import NotVerified from './errors/NotVerified';
import {Link} from "react-router-dom";
import AddHouseModal from '../components/modal/AddHouseModal';
import {Nav} from "./Nav";
import HouseInvitationModal from './modal/HouseInvitationModal';
import LoadingSpinner from "./LoadingSpinner";

const Houses = () => {
    const {token, userId} = useAuth();
    const [protectedData, setProtectedData] = useState(null);
    const [houses, setHouses] = useState([]);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showHouseSettings, setShowHouseSettings] = useState(false);
    const [currentHouseId, setCurrentHouseId] = useState(null);

    useEffect(() => {
        const fetchHouses = async (userId) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/housesAll`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        user_id: userId
                    })
                });

                if (!response.ok) {
                    console.log("ERROR");
                    throw new Error('Failed to fetch houses');
                }

                const data = await response.json();
                setHouses(data);
            } catch (err) {
                setError(err.message);
            }
        };

        const fetchProtectedData = async () => {
            if (!token) {
                setError('Žiadny token. Prosím prihláste sa.');
                return;
            }

            try {
                const api_url = process.env.REACT_APP_API_URL;
                const response = await fetch(`${api_url}/user`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    if (response.status === 403) {
                        throw new Error('Verification error.');
                    }
                    throw new Error('Chyba pri načítaní chráneného obsahu.');
                }

                const data = await response.json();
                setProtectedData(data);

                fetchHouses(data.id);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchProtectedData();
    }, [token]);

    const openSettingsModal = () => {
        setShowHouseSettings(true);
    };

    const closeSettingsModal = () => {
        setShowHouseSettings(false);
    };

    return (
        <>
            {/*<Header></Header>*/}
            <Nav></Nav>
            <div className="mainContext">
                <section className='headerSection'>
                    <h2 className="headTitle">Houses</h2>
                    <div className="flex space-x-4">
                        {showDelete ? null :(
                            <button
                            onClick={() => setShowModal(true)}
                            className="plusBtn"
                            type="button"
                        >
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12 M7 0v10"/>
                            </svg>
                            <span className="sr-only">Pridať dom</span>
                        </button>
                        )}
                        
                        <button
                            onClick={() => setShowDelete(!showDelete)}
                            className="minusBtn"
                            type="button"
                        >
                            {showDelete ? null : (
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12"/>
                                </svg>
                            )}
                            <span>{showDelete ? 'Zrušiť' : ''}</span>
                        </button>
                    </div>
                </section>

                {error ? (
                    error === 'Verification error.' ? (
                        <div className="text-red-500"><NotVerified/></div>
                    ) : (
                        <div className="text-red-500">{error}</div>
                    )
                ) : (
                    protectedData ? (
                        <section>
                            <section>
                                <AddHouseModal
                                    showModal={showModal}
                                    setShowModal={setShowModal}
                                    setHouses={setHouses} // Odovzdaj setRooms do modálu
                                    token={token}
                                    userId={userId}
                                />
                                <HouseInvitationModal
                                    showModal={showHouseSettings}
                                    setShowModal={setShowHouseSettings}
                                    token={token}
                                    houseId={currentHouseId}
                                />
                            </section>

                            <div className='m-3'>
                                <ul>
                                    {houses.length > 0 ? (
                                        houses.map((house) => (
                                            <li key={house.id} className="mb-4">
                                                <Link to={`/house/${house.id}`} className="card">
                                                    <div className="cardContent">
                                                        {house.pivot ? 
                                                        <section className="flex justify-between items-center">
                                                            <div className="flex items-center">
                                                                <h5 className="cardTitle">
                                                                    <strong>{house.name}</strong>
                                                                </h5>
                                                                <span className="ml-2 text-base text-gray-500">- {house.pivot.role}</span>
                                                            </div>
                                                            {house.pivot.role != 'guest' ? 
                                                                <button
                                                                    onClick={(e) => {
                                                                        e.stopPropagation(); // Zabráni presmerovaniu
                                                                        e.preventDefault(); // Zamedzí aj defaultnej akcii linku
                                                                        // logika pre tlačidlo
                                                                        setCurrentHouseId(house.id);
                                                                        openSettingsModal();
                                                                    }}
                                                                    className="flex items-center rounded-full p-3 hover:bg-gray-800">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/AddUser.png'}
                                                                            alt="settings"
                                                                            className="max-w-7 dark:invert"
                                                                        />
                                                                    </button> : <></>}
                                                            </section>
                                                            :
                                                            <h5 className="cardTitle"><strong>{house.name}</strong></h5> 
                                                        }
                                                    </div>
                                                </Link>
                                            </li>
                                        ))
                                    ) : (
                                        <p>Nemáte žiadne domy.</p>
                                    )}
                                </ul>
                            </div>
                        </section>
                    ) : (
                        <LoadingSpinner></LoadingSpinner>
                    )
                )}
            </div>
        </>
    );
};

export default Houses;
